export default function Company() {
    return (
        <div id="Company">
            <div className="contents_02">
                <div className="cont_top_img">
                    <div className="inner">
                        <p>실증 참여기업 소개</p>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <a rel="noopener noreferrer" href="http://bpnsolution.com/" target="_blank"><img src={require("../images/company_logo_bpns.png").default} alt="비피앤솔루션"/></a>
                            <p className="company_name">&#40;주&#41;비피앤솔루션</p>
                            <p>규제자유특구사업자/주관기관</p>
                            <p className="company_info_box">- IoT/SI/블록체인 서비스 개발 전문기업<br/>- B fresh(블록체인 스마트 물류서비스) 사업 총괄.구축.운영</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <a rel="noopener noreferrer" href="https://www.btp.or.kr/" target="_blank"><img src={require("../images/company_logo_btp.png").default} alt="부산테크노파크"/></a>
                            <p className="company_name">&#40;재&#41;부산테크노파크</p>
                            <p>규제자유특구사업자/참여기관</p>
                            <p className="company_info_box">- 해양물류/수출지원서비스 전문기관<br/>- B fresh 수출지원시스템 설계.구축</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <div><img src={require("../images/company_logo_esp.png").default} alt="이에스피"/></div>
                            <p className="company_name">&#40;주&#41;이에스피</p>
                            <p>규제자유특구사업자&#40;신규&#41;/참여기관</p>
                            <p className="company_info_box">- IoT 장비 개발.공급<br/>- B fresh 전용 IoT 장비 개발.설치.유지보수</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <div><img src={require("../images/company_logo_questx.png").default} alt="퀘스트엑스"/></div>
                            <p className="company_name">&#40;주&#41;퀘스트엑스</p>
                            <p>규제자유특구사업자&#40;신규&#41;/참여기관</p>
                            <p className="company_info_box">- 스마트 수산물 유통.가공기업<br/>- B fresh 산지 가공.공급 업무 수행</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <div><img src={require("../images/company_logo_mp.png").default} alt="마린플랫"/></div>
                            <p className="company_name">&#40;주&#41;마린플랫</p>
                            <p>규제자유특구사업자&#40;신규&#41;/참여기관</p>
                            <p className="company_info_box">- 선용품 공급시스템 구축.운영<br/>- B flesh 수출지원서비스 전담 운영</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <p className="company_name">파도소리&#40;주&#41;</p>
                            <p>실증참여사업자</p>
                            <p className="company_info_box">- 부산시 사하구 소재 수산물 가공 전문기업<br/>- B fresh 수산물 2차가공 및 유통 업무 수행</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <p className="company_name">대청홀푸드</p>
                            <p>실증참여사업자</p>
                            <p className="company_info_box">- 부산시 서구 소재 수산물 유통 전문기업<br/>- B fresh 수산물 유통 및 마케팅 업무 수행</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="company_info">
                            <p className="company_name">소비자 유통점</p>
                            <p>실증참여사업자</p>
                            <p className="company_info_box">- 부산시 전역 중소형 판매유통망<br/>- B fresh 수산물 판매 업무 수행</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}