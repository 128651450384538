import { Switch, Route } from "react-router-dom";
import Header from "./Header";
import Calculation from "./Calculation";
import Company from "./Company";
import Contactus from "./Contactus";
import Intro from "./Intro";
import Home from "./Home";
import Bottom from "./Bottom";

export default function Root() {
    return (
        <div id="Root">
            <Header />
            <Switch>
                <Route exact path="/Calculation">
                    <Calculation />
                </Route>
                <Route exact path="/Company">
                    <Company />
                </Route>
                <Route exact path="/Contactus">
                    <Contactus />
                </Route>
                <Route exact path="/Intro">
                    <Intro />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>
            <Bottom />
        </div>
    );
}