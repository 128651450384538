export default function Intro() {
    return (
        <div id="Intro">
            <div className="contents">
                <div className="cont_top_img">
                    <div className="inner">
                        <p>비프레쉬 소개</p>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">사업소개</div>
                        <div className="cont_txt">2019년 중소벤처기업부가 추진한 규제자유특구 지정 사업에서 부산광역시가 "블록체인“ 으로 규제자유특구로 선정 되었습니다. 블록체인 관련 4개 세부 과제 중 물류 분야로 &#40;주&#41;비피앤솔루션과 &#40;재&#41;부산테크노파크가 &#39;블록체인 기반의 스마트 해양물류 플랫폼 실증사업&#39;을 수행하게 되었으며, <span className="bluebold">&#39;B fresh &#40;비프레쉬&#41;&#39;</span>는 해당 서비스의 브랜드명입니다.</div>
                        <img src={require("../images/info_img_01.png").default} alt="사업소개이미지"/>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">서비스 흐름도</div>
                        <div className="cont_txt">저녁 식사로 준비된 고등어가 맛있었다고요? 특별히 맛있는 이유를 블록체인 기술을 활용하면 바로 알 수 있습니다. <span className="bold">수산물의 유통과정을 위변조가 어려운 블록체인 네트워크에 모두 기록하고 앱을 통해 누구나 확인할 수 있습니다.</span></div>
                        <img src={require("../images/info_img_02.png").default} alt="서비스흐름도"/>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">실증시설 및 장비</div>
                        <div className="cont_box">
                            <div className="cont_txt"><span className="bluebold">■ 실증사업 현장</span><br/>소재지 : 부산시 사하구 다산로 262 (신평장림일반산업단지 내)<br/>규모 : 약 200평 단층 공장건물<br/>구성 : 스마트 물류시설-약 100평, 스마트 수산물 가공시설-약100평 으로 구성</div>
                            <img src={require("../images/info_img_03.png").default} alt="실증사업현장"/>
                        </div>
                        <div className="cont_box">
                            <div className="cont_txt"><span className="bluebold">■ 실증장비</span><br/><span className="bold">스마트 콜드체인 화물차량 3대 운용</span><br/> - 스마트 냉장 덤프트럭 1대<br/> - 친환경 냉장냉동 탑차 1대<br/> -친환경 냉장 탑차 1대<br/><br/><span className="bold">스마트 수산물 가공/분류 시설</span><br/> - 침지식 상승 리프트 1기<br/> - 컨배어 세척기 1기<br/> - 벨트식 중량 선별기 1기<br/> - 냉장실 1기<br/> - 냉동실 1기<br/> - 스마트 태그 인식기 1기<br/> - 스마트 콜드체인 관제 키오스크 2기</div>
                            <img src={require("../images/info_img_04.png").default} alt="실증장비"/>
                        </div>
                        <div className="cont_box">
                            <div className="cont_txt"><span className="bluebold">■ 현황 모니터링</span><br/> - 제품의 온습도 데이터 확인<br/> - 차량 운행 경로 및 이상정보</div>
                            <img src={require("../images/info_img_05.png").default} alt="현황 모니터링"/>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">수출지원 플랫폼 소개</div>
                        <img src={require("../images/info_img_06.png").default} alt="수출지원 플랫폼 소개"/>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">기대효과</div>
                        <div className="box">
                            <div><span className="bluebold">■ 스마트 콜드체인 플랫폼 서비스</span><br/> - 신선물류 사업자와 소비자를 위한 미래형 유통ㆍ물류 체계<br/> - 오프라인 물류체계의 새로운 도전과 혁신<br/> - 불법거래 예방과 물류비용 및 에너지 절감을 위한 혁신
                            </div>
                            <div><span className="bluebold">■ IoT 콜드체인 장비 및 시설</span><br/> - 콜드체인 정보(온도, 습도, 위치, 사고여부, 이상거래 등)<br/> - 스마트 콜드체인 전용 차량 및 시설의 고부가가치 상품화 실현<br/> - 오프라인 신선물류 및 유통 사업자의 새로운 시장 경쟁력 제공
                            </div>
                        </div>
                        <div className="box">
                            <div><span className="bluebold">■ 블록체인 기반 콜드체인 거래 서비스</span><br/> - 거래 당사자에 의한 독립적/주도적인 고신뢰 거래 체계 실현<br/> - 개인정보 및 거래 정보의 높은 보안 수준 확보<br/> - 빅데이터와 인공지능 기술의 접목을 통한 시장 경쟁력 확보
                            </div>
                            <div><span className="bluebold">■ 스마트 콜드체인 수출지원 서비스</span><br/> - 신선물류의 편리하고 신속한 수출업무 처리 지원<br/> - 국제 무역항으로서의 부산항의 위상 증대<br/> - 선용품 공급사의 신선물류 서비스 부가가치 증대
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit">Bfresh가 바꾸는 미래&#33;</div>
                        <div className="future_txt">
                            <div className="cont_txt_future"><span className="bluebold">‘B Fresh’</span>를 통해서 유통되는 모든 수산물은 생산지에서 소매점까지 유통되는 과정의 모든 정보를<br/>블록체인에 기록하여 소비자가 직접 확인하여, 가장 신선한 상품을 믿고 선택할 수 있습니다.<br/><br/><span className="bluebold">‘B Fresh’</span>를 활용하는 지역 중소 유통·물류 사업자는 대형 유통기업들과 경쟁할 수 있는 역량을<br/>가질 수 있습니다.<br/><br/>블록체인과 IoT 기술로 대변되는<span className="bluebold">‘B fresh’</span>는 글로벌 경쟁력을 가진 미래 물류 서비스 기술로서 지역 전통산업과 첨단벤처산업이 협력하여 세계로 나아가는 K-ICT(한국형 정보기술수출산업)의<br/>견인차로서 자리매김 할 것입니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
