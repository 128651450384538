import { useEffect, useRef, useState } from "react";

export default function Home() {
    const [anim1, setAnim1] = useState({ top: 300, opacity: 0 });
    const [anim2, setAnim2] = useState({ top: 50, opacity: 0 });
    const [anim3, setAnim3] = useState({ top: 50, opacity: 0 });
    const [anim4, setAnim4] = useState({ top: 50, opacity: 0, transform: "scale( 0.7 )" });
    const animManager = useRef(0);

    useEffect(() => {
        const scroll = () => {
            if(animManager.current < 3) {
                const scrollTop = window.pageYOffset;
                if(scrollTop > 850) {
                    setAnim3({ top: 0, opacity: 1 })
                    setAnim4({ transform: "scale( 1 )", top: 0, opacity: 1 })
                    animManager.current = 4;
                }
            }
        }
        
        if(animManager.current === 0) {
            setAnim1({ top: 120, opacity: 1 })
            setTimeout(() => setAnim2({ top: 0, opacity: 1 }), 1000)
            animManager.current = 2;
        }
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, [])
    
    return (
        <div id="Home">
            <div className="main_01">
                <div className="main_01_top">
                    <div className="inner">
                        <div className="main_01_top_cont">
                            <img 
                                style={{ position: "relative", top: anim1.top, opacity: anim1.opacity, transition: "1.5s", }} 
                                src={require("../images/mainimg_tit.png").default} 
                                alt="신선하다! 정직하다! 믿을 수 있다! 블록체인 기반 스마트 해양물류 플랫폼 서비스"/>
                        </div>
                    </div>
                </div>
                <div className="main_01_bottom">
                    <div className="inner">
                        <div 
                            style={{ position: "relative", top: anim2.top, opacity: anim2.opacity, transition: "1.5s", }} 
                            className="QR">
                            <img src={require("../images/mainimg_qr.png").default} alt="QR"/>
                            <p className="QR_txt">물류신선도를 확인하는 정확하고 빠른 방법.<br/>지금 바로{" "}
                            <span className="bfresh_under_line">비프레쉬</span>를 검색하고 설치 하세요&#33;</p>
                        </div>	
                    </div>
                </div>
            </div>
            <div className="main_02">
                <div className="inner">
                    <div style={{ position: "relative", top: anim3.top, opacity: anim3.opacity, transition: "1.5s", }} >
                        <img src={require("../images/main_deco_fish.png").default} alt="아이콘"/>
                        <p className="main_02_txt">신선한 제품과 정직한 거래를 고객이 직접 확인하는<br/>미래형 물류 서비스</p>
                    </div>
                    <img 
                        style={{ position: "relative", top: anim4.top, transform: anim4.transform, opacity: anim4.opacity, transition: "1.5s", }}
                        src={require("../images/main_img_02.png").default} alt="Bfresh설명"/>
                </div>
            </div>
            <div className="main_03">
                <div className="inner">
                    <ul className="companylogo">
                        <li><a rel="noopener noreferrer" href="https://www.mss.go.kr/site/smba/main.do" target="_blank"><img src={require("../images/main_logo_mss.png").default} alt="중소벤처기업부"/></a></li>
                        <li><a rel="noopener noreferrer" href="http://www.busan.go.kr/open/index.jsp" target="_blank"><img src={require("../images/main_logo_busancity.png").default} alt="부산광역시"/></a></li>
                        <li><a rel="noopener noreferrer" href="http://bpnsolution.com/" target="_blank"><img src={require("../images/main_logo_bpns.png").default} alt="비피앤솔루션"/></a></li>
                        <li><a rel="noopener noreferrer" href="https://www.btp.or.kr/" target="_blank"><img src={require("../images/main_logo_btp.png").default} alt="부산테크노파크"/></a></li>
                        <li><img src={require("../images/main_logo_questx.png").default} alt="퀘스트엑스"/></li>
                        <li><img src={require("../images/main_logo_esp.png").default} alt="이에스피"/></li>
                        <li><img src={require("../images/main_logo_mp.png").default} alt="마린플랫"/></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}