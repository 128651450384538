import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const mainNavs = [
    { path: "/Intro", name: "비프레쉬 소개" },
    { path: "/Calculation", name: "주요 데이터의 산출방식" },
    { path: "/Company", name: "실증 참여기업 소개" },
    { path: "/Contactus", name: "문의하기" },
]

const HeaderContents = () => {
    const [mainNav, setMainNav] = useState("/")
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;

    const push = (pathname) => {
        history.push(pathname)
        window.scrollTo({ top: 0, behavior: "auto" })
    }

    useEffect(() => {
        setMainNav(pathname)
    }, [pathname])


    return <>
        <h1 className="bfreshlogo">
            <div className="cursor" onClick={() => push("/")} >
                <img src={require("../images/header_logo_bfresh.png").default} alt="Bfresh"/>
            </div>
        </h1>
        <ul className="gnb clearboth">
            {mainNavs.map((item, i) => {
                const { path, name } = item;
                const calssName = mainNav === path ? "navOn" : "";
                return <li key={i}><div className={`cursor ${calssName}`} onClick={() => push(path)} >{name}</div></li>
            })}
        </ul>
    </>
}

const introSubNavs = [
    { name: "사업소개", top: 200 },
    { name: "서비스 흐름도", top: 1000 },
    { name: "실증시설 및 장비", top: 1900 },
    { name: "수출지원 플랫폼 소개", top: 4500 },
    { name: "기대효과", top: 5100 },
    { name: "B fresh가 바꾸는 미래!", top: 5400 },
]

const calculationSubNavs = [
    { name: "물류신선도 기준", top: 200 },
    { name: "비상시 방사능 오염 측정 기준 적용", top: 2400 },
]

export default function Header() {
    const [subNavIdx, setSubNavIdx] = useState(-1)
    const location = useLocation();
    const { pathname } = location;
    const subNavs = pathname === "/Intro" ? introSubNavs : calculationSubNavs;

    const scrollTo = (top) => {
        window.scrollTo({ top, behavior: "smooth" })
    }

    const scroll = useCallback(() => {
        const scrollTop = window.pageYOffset;
        if(scrollTop < 200 - 1) { if(subNavIdx !== -1) setSubNavIdx(-1) }
        else {
            for(let i = subNavs.length - 1; i > -1; i--) {
                const { top } = subNavs[i]
                if(scrollTop >= top) { 
                    if(subNavIdx !== i) setSubNavIdx(i)
                    break;
                }
            }
        }
    }, [subNavIdx, subNavs])

    useEffect(() => {
        if(pathname === "/Intro" || pathname === "/Calculation") window.addEventListener('scroll', scroll);
        else window.removeEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, [pathname, scroll])

    if(pathname === "/Intro" || pathname === "/Calculation") {
        return (
            <div id="Header">
                <nav className="subnav">
                    <div className="nav_top clearboth">
                        <div className="inner">
                            <HeaderContents />
                        </div>
                    </div>
                    <div className="nav_bottom">
                        <div className="inner">
                            <ul className="lnb clearboth">
                                <li className="lnbtit">비프레쉬 소개</li>
                                <li>|</li>
                                {subNavs.map((item, i) => {
                                    const { name, top } = item;
                                    const calssName = subNavIdx === i ? "navOn" : "";
                                    return <li key={i}><div className={`cursor ${calssName}`} onClick={() => scrollTo(top)}>{name}</div></li>
                                })}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
    else {
        return (
            <div id="Header">
                <nav className="mainnav">
                    <div className="inner clearboth">
                        <HeaderContents />
                    </div>
                </nav>
            </div>
        )
    }
}    
