export default function Calculation() {
    return (
        <div id="Calculation">
            <div className="contents">
                <div className="cont_top_img">
                    <div className="inner">
                        <p>주요 데이터의 산출방식</p>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit bluebold">물류신선도 기준</div>
                        <div className="cont_txt"><span className="bold">제품의 유통.물류 총 시간에서 비신선 환경 노출 비중에 가중치를 반영하여 산정</span><br/><br/>
                            <span className="bold">&#91;최고신선&#93;</span> 물류신선도 95&#37; 이상<br/>
                            <span className="bold">&#91;매우신선&#93;</span> 물류신선도 90&#37; &#126; 94.99&#37;<br/>
                            <span className="bold">&#91;보통신선&#93;</span> 물류신선도 80&#37; &#126; 89.99&#37;<br/>
                            <span className="bold">&#91;보통&#93;</span> 물류신선도 70&#37; &#126; 79.99&#37;<br/>
                            <span className="bold">&#91;신선기준 이하&#93;</span> 물류신선도 70&#37; 미만<br/><br/>

                                                <span className="bold">※ 물류신선도 산정 기준</span><br/>
                                - 신선제품의 유통.물류 총 시간을 단위시간&#40;10분&#41;으로 나누어 단위물류신선도 산정<br/>
                                - 각 단위물류신선도에 해당 시간별 산정가중치 적용<br/>
                                - 수산전문인&#40;부경대학교&#41;;의 자문의견 반영<br/>
                                - &#40;예정&#41;; 축적된 유통/물류 빅데이터를 활용하여 물류신선도 반영<br/><br/>

                            <span className="bold">※ 물류신선도 산정 예시</span><br/>
                            <span className="bold">&#91;물류신선도 산정예시 조건&#93;</span><br/>
                                - 물류신선도는 본 플랫폼 서비스에서 신선물류 산정에 최초로 적용하는 개념으로, 36시간을 신선물류&#40;수산물&#41; 한계시간으로 기준하고,<br/>
                                &nbsp;&nbsp;&nbsp;그 시간을 10분 단위로 216구간으로 나누어 각 구간별로 비신선환경 노출시간을 각 단위 신선도에 누적 반영한 것임. <br/>
                                - 아래 산정 예시는 양륙한 수산물을 3시간&#40;18구간&#41; 동안 이송하는 동안에 적용되는 사례를 현 산정 방법에 따라 계산한 것임.
                        </div>
                        <img src={require("../images/table.png").default} alt="물류신선도 산정식" style={{marginBottom: 20}}/>
                        <div className="cont_txt">
                            ※ 물류신선도는 현재 정립되어 있지 않은 새로운 개념이지만, 해당 분야의 전문 연구진 및 학계와의 연구협업, 자문검토를 통해서 보다 객관적이고 합리적인 기준의 물류신선도 측정 방법으로 개선해 나갈 예정입니다. 
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="inner">
                        <div className="cont_tit bluebold">비상시 방사능 오염 측정 기준 적용</div>
                        <div className="cont_txt">본 실증에서 이루어지는 방사능 오염측정은 산지 수집후 물류이동 과정에서 해당 제품에서 발생 되는 표면방사능 검출 여부를 판단하는 용도에 한정되며 해당 물류 단위에 포함된 제품의 방사능 오염 정밀 검사 또는 폐기 등의 후속 조치를 내리기 위한 용도로만 사용되어 집니다. 방사능 오염에 대해 안전하지 않다고 판단된 제품은 원천적으로 최종 소비자에게 전달 될 수가 없기에 안전하다는 데이터만 보여 지게 됩니다.<br/><br/>
                            <span className="bold">&#91;안전&#93; 1μSv/h 미만</span><br/> - 피검사 상품의 방사능 오염도 기준 이하<br/> - 상품의 유통 거래 지속<br/><br/>
                            <span className="bold">&#91;주의/정밀측정 요망&#93; 1μSv/h&#126;100μSv/h</span><br/>- 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황 즉시 통보 &#40;자동&#41;<br/> - 상품의 유통 즉시 중단 &#40;기준에 따라 폐기 및 반품 처리&#41;<br/> - 접촉자 즉시 연락후 제염 조치 &#40;탈의, 세수 및 전신세척 등&#41; 통보 및 재측정 지시, 제염조치 이후에도 방사선량률 측정 수치가 크게 변하지 않는 경우 기준에 따라 지정병원 후송 또는 제염 재실시<br/><br/>
                            <span className="bold">&#91;위험&#93; 100μSv/h 초과</span><br/> - 부산 스마트 콜드체인 상황실에 해당 방사능 오염 상황 즉시 통보 &#40;자동&#41;<br/> - 상품의 유통 즉시 중단 후 상황 발생 장소에 정지 &#40;이동금지&#41;<br/> - 상황실은 즉시 119 및 지정병원에 연락 후 지시에 따라 행동<br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}