import { BrowserRouter } from "react-router-dom";
import Root from "./components/Root"
import { transitions, Provider as AlertProvider } from "react-alert";
import Alert from "./components/Alert"
import "./css/common.css"
import "./css/style.css"

const ALERT_OPTIONS = { timeout: 4000, transition: transitions.FADE };

export default function App() {
    return (
        <div id="App">
            <BrowserRouter>
                <AlertProvider template={Alert} {...ALERT_OPTIONS}>
                    <Root />
                </AlertProvider>
            </BrowserRouter>
        </div>
    );
}
