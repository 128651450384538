import { useHistory } from 'react-router-dom';

export default function Bottom() {
  const history = useHistory();

  return (
    <div id="Bottom">
      <footer>
        <div className="inner">
          <div className="footer_info">
            <h1>
              <div
                className="cursor"
                onClick={() => {
                  history.push('/');
                  window.scrollTo({ top: 0, behavior: 'auto' });
                }}>
                <img src={require('../images/footer_logo_bfresh.png').default} alt="Bfresh" />
              </div>
            </h1>
            <p>
              49478 부산광역시 사하구 장림동 다산로 266 B동 <br />
              <br />
              본사 | 48304 부산광역시 수영구 광남로 44, 4층 (남천동, 남성빌딩)
              <br />
              Tel: 051-917-9131~4 | Fax: 0303-3130-8113
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
