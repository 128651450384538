import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert, positions } from "react-alert";
import emailjs, { init } from 'emailjs-com';

const initialState = {
    title: "",
    number: "",
    email: "",
    message: "",
    checked: false,
}

export default function Contactus() {
    const alert = useAlert();
    const [state, setState] = useState(initialState);
    const history = useHistory();

    const onChange = (evt) => {
        const name = evt.target.name;
        const value = name === "checked" ?  evt.target.checked : evt.target.value;
        setState({...state, [name]: value });
    }
    
    const check = () => {
        let { title, number, email, message, checked } = state;
        if(title === "") return false
        if(number === "") return false
        if(email === "") return false
        if(message === "") return false
        if(!checked) return false
        return true
    }

    const question = () => {
        const checker = check();
        if(checker) {
            const { title, number, email, message } = state;
            emailjs.send("service_ws20j8b","template_rjfnw8g",{
                title: title,
                fromName: email,
                number: number,
                message: message,
                replyTo: "",
            }).then((res) => {
                alert.show("문의글 접수가 완료됬습니다.", {
                    position: positions.TOP_CENTER,
                    type: "success",
                    transition : 'fade'
                });
                history.goBack()
            }).catch((err) => {
                alert.show("알수없는 오류로 문의글 작성에 실패했습니다.", {
                    position: positions.TOP_CENTER,
                    type: "error",
                    transition : 'fade'
                });
            })   
        }
        else {
            alert.show("누락된 정보가 있습니다. 제대로 입력해 주세요.", {
                position: positions.TOP_CENTER,
                type: "error",
                transition : 'fade'
            });
        }
    }

    useEffect(() => {
        init("user_b3GvPb77iyuIYcH8NgFZt");
    }, [])

    return (
        <div id="Contactus">
            <div className="contents_02">
                <div className="cont_top_img">
                    <div className="inner">
                        <p>문의하기</p>
                    </div>
                </div>
                <div className="formlist">
                    <div className="inner">
                        <ul>
                            <li>
                                <label htmlFor="title">제목</label>
                                <input 
                                    name="title"
                                    value={state.title} 
                                    onChange={onChange} 
                                    type="text" id="title" placeholder="제목을 입력해 주세요" className="inputbox"/>
                            </li>
                            <li>
                                <label htmlFor="number">연락처</label>
                                <input 
                                    name="number"
                                    value={state.number} 
                                    onChange={onChange} 
                                    type="text" id="number" placeholder="연락처를 입력해 주세요" className="inputbox"/>
                            </li>
                            <li>
                                <label htmlFor="email">이메일주소</label>
                                <input 
                                    name="email"
                                    value={state.email} 
                                    onChange={onChange} 
                                    type="email" id="email" placeholder="sample@mail.com" className="inputbox"/>
                            </li>
                            <li>
                                <label htmlFor="message">내용</label>
                                <textarea 
                                    name="message"
                                    value={state.message} 
                                    onChange={onChange} 
                                    id="message" className="message"></textarea>
                            </li>	
                        </ul>
                        <div className="inquiry">
                            <div className="inner">
                                <p className="inquiry_tit">개인정보 수집 및 이용 동의</p>
                                <p className="inquiry_txt">
                                    1. 개인정보 수집 및 이용 목적<br/>
                                    - 동의 확인 및 문의 응답에 필요한 정보<br/><br/>
                                    2. 개인정보 수집항목<br/>
                                    - 수집항목: 연락처, 이메일 주소<br/>
                                    - 고유식별정보&#58; 없음<br/><br/>
                                    3. 개인정보의 보유 및 이용기간<br/>
                                    - 문의 응답 시에만 보유, 이용 됩니다.<br/><br/>
                                    4. 제 3자 제공 및 위탁 사항<br/>
                                    - 없음</p>
                                <input 
                                    name="checked"
                                    checked={state.checked} 
                                    onChange={onChange} 
                                    type="checkbox" />
                                위 내용을 읽었으며 동의합니다.
                            </div>
                        </div>
                        <div className="btn_box">
                            <button onClick={() => history.goBack()} type="button" className="btn_cancel">취소하기</button>
                            <button onClick={question} type="button" className="btn_confirm">문의하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}